.Intro-container{
    height: 100vh;
    width: 100%;
    align-items:center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    object-fit: contain;
}
.Intro-inner{
    display: flex;
    flex-direction: column;
    padding-bottom: 15%;
}
h1.i1{
    color: #b3cde0d0;
    font-size: 5.2em;
    font-family: 'Bahnschrift';
    text-align: left;
}
p.i1{
    color: #8ffddf;
    font-size: 1.3em;
    padding-bottom: 1%;
    text-align: left;
    font-family: 'Ebrima';
}
p.i2{
    padding-top: 1%;
    color: #b3cde0d0;
    font-size: 1.3em;
    font-family: 'Ebrima';
    text-align: left;
}

@media screen and (max-width: 960px) {
    .Intro-inner > h1{
      font-size: 2.2em;
    }
    .Intro-container{
        width: 100%;
    }
    
  }