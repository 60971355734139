.Experience-container{
    height: 1000px;
    width: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    position: relative;
    /* background-color: #010f25; */
    

}
.Experience-holder{
  flex-direction: row;
  display: flex;
}
.Experience-inner{
  height: 800px;
  width: 800px;
  flex-direction: column;
  position: absolute;
  justify-content: center;
  display: flex;
  text-decoration: none;
}
.descript{
    height: 400px;
    width:100%;
    margin-left: 5%;
    display: inline-block;
    /* background-color: navy; */
}
.joblist{
    justify-content: space-between;
    height: 400px;
    width: 150px;
    display: flex;
    flex-direction: column;
    text-decoration: none;
}
.job3{
    display:flex;
    justify-content: center;   
    flex-direction: column; 
    height:33%;
}


h1.a1 {
    color: #8ffddf;
    font-size: 50px;
    margin-top: -100px;
    padding-bottom: 20px;
    width:45%;
    font-family: 'Bahnschrift';
  }

.titleHead{
    color: #fff;
    font-family: 'Ebrima';
    font-size: 23px;
    /* font-size: 2em; */
}

p.date{
    color: #b3cde0d0;
    font-size: 15px;
    /* font-size: 1.2em; */
    font-family: 'Ebrima';
    font-style: italic;
}
p.text{
    color: #b3cde0d0;
    font-family: 'Ebrima';
    font-size:17px;
    /* font-size: 1.3em; */
}

@media screen and (max-width: 960px) {
    .Experience-inner{
      width: 100%;
    }
    
    
  }