.Work-container{
    height: 1000px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    /* box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2); */
    background-color: #010f25;
}
.inner-work {
  flex-direction: column;
  display: flex;
  justify-content: center;
  height: 300px;
  width: 800px;
  }

  .Work-container> p> a{
    text-decoration: none;
    color: #8ffddf;
  }

.Work-holder{
  display: flex;
  font-display: row;
}
.text-work1{
  width: 400px;
  margin-right: 5%;
  text-align: center;
}
.text-work2{
  width: 400px;
  margin-left: 5%;
  text-align: center;
}
  .concuss{
    width: auto;
    height: 200px;
    
  border-radius: 3%;
  border: 2px solid #8ffddf; 
  }
  .imageContainer-top{
    flex-direction: row;
    display: flex;
    align-content: center;
  }
  .imageContainer-bot{
    flex-direction: row;
    display: flex;
    align-content: center;
    padding-right: 30px;
  }

  p.a12{

    color: #b3cde0d0; 
    font-size: 17px;
    font-family: 'Ebrima';
  }
  h1.a12 {
    color: #8ffddf;
    font-size: 50px;
    text-align: center;
    padding-bottom: 60px;
    font-family: 'Bahnschrift';
  }

  @media screen and (max-width: 960px) {
  
    .inner-work{
        width: 100%;
        margin-bottom: 50px;
    }
    .inner-work{
      flex-direction: column;
    }
    .Work-holder{
      flex-direction: column;
    }
  }