  .AboutMe-container {
    height: 1000px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    position: relative;
    /* box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2); */
    background-color: #010f25;
  }
  .AboutMe-container a{
    text-decoration: none;
  }
.AboutMe-inner {
  height: 800px;
  width: 800px;
  justify-content: center;
  padding-bottom: 7%;
  flex-direction: column;
  display: flex;
}
.tempHolder{
  flex-direction: row;
  display: flex;
}
.headshot{
  width: 300px;
  height: 300px;
  border-radius: 3%;
  border: 2px solid #8ffddf; 
}
.imageContainer-AboutMe{
  flex-direction: row;
  display: flex;
  margin-left: auto;
}
.text-AboutMe{
  width: 450px;
}
p.a1{

  color: #b3cde0d0; 
  /* font-size: 1vmax; */
  font-size: 17px;
  font-family: 'Ebrima';
}
.emphText{
  color: #8ffddf;
  text-decoration:none;
}
.emphText:hover{
  text-underline-position: below;
}
h1.a1 {
    color: #8ffddf;
    font-size: 70px;
    /* margin-top: -100px;
    padding-bottom: 20px; */
    width:45%;
    font-family: 'Bahnschrift';
  }
  

  
  /* .AboutMe-btns {
    margin-top: 32px;
  }
  
  .AboutMe-btns .btn {
    margin: 6px;
  }
  
  .fa-play-circle {
    margin-left: 4px;
  } */
  
  @media screen and (max-width: 960px) {
    .AboutMe-inner{
      width: 100%;
    }
    .tempHolder{
      flex-direction: column;
    }
    
  }
  
  /* @media screen and (max-width: 768px) {
    .AboutMe-container > h1 {
      font-size: 50px;
      margin-top: -100px;
    }
  
    .AboutMe-container > p {
      font-size: 30px;
    }
  
    .btn-mobile {
      display: block;
      text-decoration: none;
    }
  
    .btn {
      width: 100%;
    }
  } */

 